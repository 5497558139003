export const map = [
  { code: "AF", name: "Afghanistan", timezone: "Asia/Kabul" },
  { code: "AL", name: "Albania", timezone: "Europe/Tirane" },
  { code: "DZ", name: "Algeria", timezone: "Africa/Algiers" },
  { code: "AS", name: "American Samoa", timezone: "Pacific/Pago_Pago" },
  { code: "AD", name: "Andorra", timezone: "Europe/Andorra" },
  { code: "AO", name: "Angola", timezone: "Africa/Luanda" },
  { code: "AI", name: "Anguilla", timezone: "America/Anguilla" },
  { code: "AQ", name: "Antarctica", timezone: "Antarctica/Casey" },
  { code: "AQ", name: "Antarctica", timezone: "Antarctica/Davis" },
  { code: "AQ", name: "Antarctica", timezone: "Antarctica/DumontDUrville" },
  { code: "AQ", name: "Antarctica", timezone: "Antarctica/Mawson" },
  { code: "AQ", name: "Antarctica", timezone: "Antarctica/McMurdo" },
  { code: "AQ", name: "Antarctica", timezone: "Antarctica/Palmer" },
  { code: "AQ", name: "Antarctica", timezone: "Antarctica/Rothera" },
  { code: "AQ", name: "Antarctica", timezone: "Antarctica/Syowa" },
  { code: "AQ", name: "Antarctica", timezone: "Antarctica/Troll" },
  { code: "AQ", name: "Antarctica", timezone: "Antarctica/Vostok" },
  { code: "AG", name: "Antigua and Barbuda", timezone: "America/Antigua" },
  { code: "AR", name: "Argentina", timezone: "America/Argentina/Buenos_Aires" },
  { code: "AR", name: "Argentina", timezone: "America/Argentina/Catamarca" },
  { code: "AR", name: "Argentina", timezone: "America/Argentina/Cordoba" },
  { code: "AR", name: "Argentina", timezone: "America/Argentina/Jujuy" },
  { code: "AR", name: "Argentina", timezone: "America/Argentina/La_Rioja" },
  { code: "AR", name: "Argentina", timezone: "America/Argentina/Mendoza" },
  { code: "AR", name: "Argentina", timezone: "America/Argentina/Rio_Gallegos" },
  { code: "AR", name: "Argentina", timezone: "America/Argentina/Salta" },
  { code: "AR", name: "Argentina", timezone: "America/Argentina/San_Juan" },
  { code: "AR", name: "Argentina", timezone: "America/Argentina/San_Luis" },
  { code: "AR", name: "Argentina", timezone: "America/Argentina/Tucuman" },
  { code: "AR", name: "Argentina", timezone: "America/Argentina/Ushuaia" },
  { code: "AM", name: "Armenia", timezone: "Asia/Yerevan" },
  { code: "AW", name: "Aruba", timezone: "America/Aruba" },
  { code: "AU", name: "Australia", timezone: "Antarctica/Macquarie" },
  { code: "AU", name: "Australia", timezone: "Australia/Adelaide" },
  { code: "AU", name: "Australia", timezone: "Australia/Brisbane" },
  { code: "AU", name: "Australia", timezone: "Australia/Broken_Hill" },
  { code: "AU", name: "Australia", timezone: "Australia/Darwin" },
  { code: "AU", name: "Australia", timezone: "Australia/Eucla" },
  { code: "AU", name: "Australia", timezone: "Australia/Hobart" },
  { code: "AU", name: "Australia", timezone: "Australia/Lindeman" },
  { code: "AU", name: "Australia", timezone: "Australia/Lord_Howe" },
  { code: "AU", name: "Australia", timezone: "Australia/Melbourne" },
  { code: "AU", name: "Australia", timezone: "Australia/Perth" },
  { code: "AU", name: "Australia", timezone: "Australia/Sydney" },
  { code: "AT", name: "Austria", timezone: "Europe/Vienna" },
  { code: "AZ", name: "Azerbaijan", timezone: "Asia/Baku" },
  { code: "BS", name: "Bahamas", timezone: "America/Nassau" },
  { code: "BH", name: "Bahrain", timezone: "Asia/Bahrain" },
  { code: "BD", name: "Bangladesh", timezone: "Asia/Dhaka" },
  { code: "BB", name: "Barbados", timezone: "America/Barbados" },
  { code: "BY", name: "Belarus", timezone: "Europe/Minsk" },
  { code: "BE", name: "Belgium", timezone: "Europe/Brussels" },
  { code: "BZ", name: "Belize", timezone: "America/Belize" },
  { code: "BJ", name: "Benin", timezone: "Africa/Porto-Novo" },
  { code: "BM", name: "Bermuda", timezone: "Atlantic/Bermuda" },
  { code: "BT", name: "Bhutan", timezone: "Asia/Thimphu" },
  {
    code: "BO",
    name: "Bolivia, Plurinational State of",
    timezone: "America/La_Paz",
  },
  {
    code: "BQ",
    name: "Bonaire, Sint Eustatius and Saba",
    timezone: "America/Kralendijk",
  },
  { code: "BA", name: "Bosnia and Herzegovina", timezone: "Europe/Sarajevo" },
  { code: "BW", name: "Botswana", timezone: "Africa/Gaborone" },
  { code: "BR", name: "Brazil", timezone: "America/Araguaina" },
  { code: "BR", name: "Brazil", timezone: "America/Bahia" },
  { code: "BR", name: "Brazil", timezone: "America/Belem" },
  { code: "BR", name: "Brazil", timezone: "America/Boa_Vista" },
  { code: "BR", name: "Brazil", timezone: "America/Campo_Grande" },
  { code: "BR", name: "Brazil", timezone: "America/Cuiaba" },
  { code: "BR", name: "Brazil", timezone: "America/Eirunepe" },
  { code: "BR", name: "Brazil", timezone: "America/Fortaleza" },
  { code: "BR", name: "Brazil", timezone: "America/Maceio" },
  { code: "BR", name: "Brazil", timezone: "America/Manaus" },
  { code: "BR", name: "Brazil", timezone: "America/Noronha" },
  { code: "BR", name: "Brazil", timezone: "America/Porto_Velho" },
  { code: "BR", name: "Brazil", timezone: "America/Recife" },
  { code: "BR", name: "Brazil", timezone: "America/Rio_Branco" },
  { code: "BR", name: "Brazil", timezone: "America/Santarem" },
  { code: "BR", name: "Brazil", timezone: "America/Sao_Paulo" },
  {
    code: "IO",
    name: "British Indian Ocean Territory",
    timezone: "Indian/Chagos",
  },
  { code: "BN", name: "Brunei Darussalam", timezone: "Asia/Brunei" },
  { code: "BG", name: "Bulgaria", timezone: "Europe/Sofia" },
  { code: "BF", name: "Burkina Faso", timezone: "Africa/Ouagadougou" },
  { code: "BI", name: "Burundi", timezone: "Africa/Bujumbura" },
  { code: "KH", name: "Cambodia", timezone: "Asia/Phnom_Penh" },
  { code: "CM", name: "Cameroon", timezone: "Africa/Douala" },
  { code: "CA", name: "Canada", timezone: "America/Atikokan" },
  { code: "CA", name: "Canada", timezone: "America/Blanc-Sablon" },
  { code: "CA", name: "Canada", timezone: "America/Cambridge_Bay" },
  { code: "CA", name: "Canada", timezone: "America/Creston" },
  { code: "CA", name: "Canada", timezone: "America/Dawson" },
  { code: "CA", name: "Canada", timezone: "America/Dawson_Creek" },
  { code: "CA", name: "Canada", timezone: "America/Edmonton" },
  { code: "CA", name: "Canada", timezone: "America/Fort_Nelson" },
  { code: "CA", name: "Canada", timezone: "America/Glace_Bay" },
  { code: "CA", name: "Canada", timezone: "America/Goose_Bay" },
  { code: "CA", name: "Canada", timezone: "America/Halifax" },
  { code: "CA", name: "Canada", timezone: "America/Inuvik" },
  { code: "CA", name: "Canada", timezone: "America/Iqaluit" },
  { code: "CA", name: "Canada", timezone: "America/Moncton" },
  { code: "CA", name: "Canada", timezone: "America/Rankin_Inlet" },
  { code: "CA", name: "Canada", timezone: "America/Regina" },
  { code: "CA", name: "Canada", timezone: "America/Resolute" },
  { code: "CA", name: "Canada", timezone: "America/St_Johns" },
  { code: "CA", name: "Canada", timezone: "America/Swift_Current" },
  { code: "CA", name: "Canada", timezone: "America/Toronto" },
  { code: "CA", name: "Canada", timezone: "America/Vancouver" },
  { code: "CA", name: "Canada", timezone: "America/Whitehorse" },
  { code: "CA", name: "Canada", timezone: "America/Winnipeg" },
  { code: "CV", name: "Cape Verde", timezone: "Atlantic/Cape_Verde" },
  { code: "KY", name: "Cayman Islands", timezone: "America/Cayman" },
  { code: "CF", name: "Central African Republic", timezone: "Africa/Bangui" },
  { code: "TD", name: "Chad", timezone: "Africa/Ndjamena" },
  { code: "CL", name: "Chile", timezone: "America/Punta_Arenas" },
  { code: "CL", name: "Chile", timezone: "America/Santiago" },
  { code: "CL", name: "Chile", timezone: "Pacific/Easter" },
  { code: "CN", name: "China", timezone: "Asia/Shanghai" },
  { code: "CN", name: "China", timezone: "Asia/Urumqi" },
  { code: "CX", name: "Christmas Island", timezone: "Indian/Christmas" },
  { code: "CC", name: "Cocos (Keeling) Islands", timezone: "Indian/Cocos" },
  { code: "CO", name: "Colombia", timezone: "America/Bogota" },
  { code: "KM", name: "Comoros", timezone: "Indian/Comoro" },
  { code: "CG", name: "Congo", timezone: "Africa/Brazzaville" },
  {
    code: "CD",
    name: "Congo, the Democratic Republic of the",
    timezone: "Africa/Kinshasa",
  },
  {
    code: "CD",
    name: "Congo, the Democratic Republic of the",
    timezone: "Africa/Lubumbashi",
  },
  { code: "CK", name: "Cook Islands", timezone: "Pacific/Rarotonga" },
  { code: "CR", name: "Costa Rica", timezone: "America/Costa_Rica" },
  { code: "HR", name: "Croatia", timezone: "Europe/Zagreb" },
  { code: "CU", name: "Cuba", timezone: "America/Havana" },
  { code: "CW", name: "Curaçao", timezone: "America/Curacao" },
  { code: "CY", name: "Cyprus", timezone: "Asia/Famagusta" },
  { code: "CY", name: "Cyprus", timezone: "Asia/Nicosia" },
  { code: "CZ", name: "Czech Republic", timezone: "Europe/Prague" },
  { code: "CI", name: "Côte d'Ivoire", timezone: "Africa/Abidjan" },
  { code: "DK", name: "Denmark", timezone: "Europe/Copenhagen" },
  { code: "DJ", name: "Djibouti", timezone: "Africa/Djibouti" },
  { code: "DM", name: "Dominica", timezone: "America/Dominica" },
  { code: "DO", name: "Dominican Republic", timezone: "America/Santo_Domingo" },
  { code: "EC", name: "Ecuador", timezone: "America/Guayaquil" },
  { code: "EC", name: "Ecuador", timezone: "Pacific/Galapagos" },
  { code: "EG", name: "Egypt", timezone: "Africa/Cairo" },
  { code: "SV", name: "El Salvador", timezone: "America/El_Salvador" },
  { code: "GQ", name: "Equatorial Guinea", timezone: "Africa/Malabo" },
  { code: "ER", name: "Eritrea", timezone: "Africa/Asmara" },
  { code: "EE", name: "Estonia", timezone: "Europe/Tallinn" },
  { code: "ET", name: "Ethiopia", timezone: "Africa/Addis_Ababa" },
  {
    code: "FK",
    name: "Falkland Islands (Malvinas)",
    timezone: "Atlantic/Stanley",
  },
  { code: "FO", name: "Faroe Islands", timezone: "Atlantic/Faroe" },
  { code: "FJ", name: "Fiji", timezone: "Pacific/Fiji" },
  { code: "FI", name: "Finland", timezone: "Europe/Helsinki" },
  { code: "FR", name: "France", timezone: "Europe/Paris" },
  { code: "GF", name: "French Guiana", timezone: "America/Cayenne" },
  { code: "PF", name: "French Polynesia", timezone: "Pacific/Gambier" },
  { code: "PF", name: "French Polynesia", timezone: "Pacific/Marquesas" },
  { code: "PF", name: "French Polynesia", timezone: "Pacific/Tahiti" },
  {
    code: "TF",
    name: "French Southern Territories",
    timezone: "Indian/Kerguelen",
  },
  { code: "GA", name: "Gabon", timezone: "Africa/Libreville" },
  { code: "GM", name: "Gambia", timezone: "Africa/Banjul" },
  { code: "GE", name: "Georgia", timezone: "Asia/Tbilisi" },
  { code: "DE", name: "Germany", timezone: "Europe/Berlin" },
  { code: "DE", name: "Germany", timezone: "Europe/Busingen" },
  { code: "GH", name: "Ghana", timezone: "Africa/Accra" },
  { code: "GI", name: "Gibraltar", timezone: "Europe/Gibraltar" },
  { code: "GR", name: "Greece", timezone: "Europe/Athens" },
  { code: "GL", name: "Greenland", timezone: "America/Danmarkshavn" },
  { code: "GL", name: "Greenland", timezone: "America/Nuuk" },
  { code: "GL", name: "Greenland", timezone: "America/Scoresbysund" },
  { code: "GL", name: "Greenland", timezone: "America/Thule" },
  { code: "GD", name: "Grenada", timezone: "America/Grenada" },
  { code: "GP", name: "Guadeloupe", timezone: "America/Guadeloupe" },
  { code: "GU", name: "Guam", timezone: "Pacific/Guam" },
  { code: "GT", name: "Guatemala", timezone: "America/Guatemala" },
  { code: "GG", name: "Guernsey", timezone: "Europe/Guernsey" },
  { code: "GN", name: "Guinea", timezone: "Africa/Conakry" },
  { code: "GW", name: "Guinea-Bissau", timezone: "Africa/Bissau" },
  { code: "GY", name: "Guyana", timezone: "America/Guyana" },
  { code: "HT", name: "Haiti", timezone: "America/Port-au-Prince" },
  {
    code: "VA",
    name: "Holy See (Vatican City State)",
    timezone: "Europe/Vatican",
  },
  { code: "HN", name: "Honduras", timezone: "America/Tegucigalpa" },
  { code: "HK", name: "Hong Kong", timezone: "Asia/Hong_Kong" },
  { code: "HU", name: "Hungary", timezone: "Europe/Budapest" },
  { code: "IS", name: "Iceland", timezone: "Atlantic/Reykjavik" },
  { code: "IN", name: "India", timezone: "Asia/Kolkata" },
  { code: "ID", name: "Indonesia", timezone: "Asia/Jakarta" },
  { code: "ID", name: "Indonesia", timezone: "Asia/Jayapura" },
  { code: "ID", name: "Indonesia", timezone: "Asia/Makassar" },
  { code: "ID", name: "Indonesia", timezone: "Asia/Pontianak" },
  { code: "IR", name: "Iran, Islamic Republic of", timezone: "Asia/Tehran" },
  { code: "IQ", name: "Iraq", timezone: "Asia/Baghdad" },
  { code: "IE", name: "Ireland", timezone: "Europe/Dublin" },
  { code: "IM", name: "Isle of Man", timezone: "Europe/Isle_of_Man" },
  { code: "IL", name: "Israel", timezone: "Asia/Jerusalem" },
  { code: "IT", name: "Italy", timezone: "Europe/Rome" },
  { code: "JM", name: "Jamaica", timezone: "America/Jamaica" },
  { code: "JP", name: "Japan", timezone: "Asia/Tokyo" },
  { code: "JE", name: "Jersey", timezone: "Europe/Jersey" },
  { code: "JO", name: "Jordan", timezone: "Asia/Amman" },
  { code: "KZ", name: "Kazakhstan", timezone: "Asia/Almaty" },
  { code: "KZ", name: "Kazakhstan", timezone: "Asia/Aqtau" },
  { code: "KZ", name: "Kazakhstan", timezone: "Asia/Aqtobe" },
  { code: "KZ", name: "Kazakhstan", timezone: "Asia/Atyrau" },
  { code: "KZ", name: "Kazakhstan", timezone: "Asia/Oral" },
  { code: "KZ", name: "Kazakhstan", timezone: "Asia/Qostanay" },
  { code: "KZ", name: "Kazakhstan", timezone: "Asia/Qyzylorda" },
  { code: "KE", name: "Kenya", timezone: "Africa/Nairobi" },
  { code: "KI", name: "Kiribati", timezone: "Pacific/Kanton" },
  { code: "KI", name: "Kiribati", timezone: "Pacific/Kiritimati" },
  { code: "KI", name: "Kiribati", timezone: "Pacific/Tarawa" },
  {
    code: "KP",
    name: "Korea, Democratic People's Republic of",
    timezone: "Asia/Pyongyang",
  },
  { code: "KR", name: "Korea, Republic of", timezone: "Asia/Seoul" },
  { code: "KW", name: "Kuwait", timezone: "Asia/Kuwait" },
  { code: "KG", name: "Kyrgyzstan", timezone: "Asia/Bishkek" },
  {
    code: "LA",
    name: "Lao People's Democratic Republic",
    timezone: "Asia/Vientiane",
  },
  { code: "LV", name: "Latvia", timezone: "Europe/Riga" },
  { code: "LB", name: "Lebanon", timezone: "Asia/Beirut" },
  { code: "LS", name: "Lesotho", timezone: "Africa/Maseru" },
  { code: "LR", name: "Liberia", timezone: "Africa/Monrovia" },
  { code: "LY", name: "Libya", timezone: "Africa/Tripoli" },
  { code: "LI", name: "Liechtenstein", timezone: "Europe/Vaduz" },
  { code: "LT", name: "Lithuania", timezone: "Europe/Vilnius" },
  { code: "LU", name: "Luxembourg", timezone: "Europe/Luxembourg" },
  { code: "MO", name: "Macao", timezone: "Asia/Macau" },
  {
    code: "MK",
    name: "Macedonia, the Former Yugoslav Republic of",
    timezone: "Europe/Skopje",
  },
  { code: "MG", name: "Madagascar", timezone: "Indian/Antananarivo" },
  { code: "MW", name: "Malawi", timezone: "Africa/Blantyre" },
  { code: "MY", name: "Malaysia", timezone: "Asia/Kuala_Lumpur" },
  { code: "MY", name: "Malaysia", timezone: "Asia/Kuching" },
  { code: "MV", name: "Maldives", timezone: "Indian/Maldives" },
  { code: "ML", name: "Mali", timezone: "Africa/Bamako" },
  { code: "MT", name: "Malta", timezone: "Europe/Malta" },
  { code: "MH", name: "Marshall Islands", timezone: "Pacific/Kwajalein" },
  { code: "MH", name: "Marshall Islands", timezone: "Pacific/Majuro" },
  { code: "MQ", name: "Martinique", timezone: "America/Martinique" },
  { code: "MR", name: "Mauritania", timezone: "Africa/Nouakchott" },
  { code: "MU", name: "Mauritius", timezone: "Indian/Mauritius" },
  { code: "YT", name: "Mayotte", timezone: "Indian/Mayotte" },
  { code: "MX", name: "Mexico", timezone: "America/Bahia_Banderas" },
  { code: "MX", name: "Mexico", timezone: "America/Cancun" },
  { code: "MX", name: "Mexico", timezone: "America/Chihuahua" },
  { code: "MX", name: "Mexico", timezone: "America/Ciudad_Juarez" },
  { code: "MX", name: "Mexico", timezone: "America/Hermosillo" },
  { code: "MX", name: "Mexico", timezone: "America/Matamoros" },
  { code: "MX", name: "Mexico", timezone: "America/Mazatlan" },
  { code: "MX", name: "Mexico", timezone: "America/Merida" },
  { code: "MX", name: "Mexico", timezone: "America/Mexico_City" },
  { code: "MX", name: "Mexico", timezone: "America/Monterrey" },
  { code: "MX", name: "Mexico", timezone: "America/Ojinaga" },
  { code: "MX", name: "Mexico", timezone: "America/Tijuana" },
  {
    code: "FM",
    name: "Micronesia, Federated States of",
    timezone: "Pacific/Chuuk",
  },
  {
    code: "FM",
    name: "Micronesia, Federated States of",
    timezone: "Pacific/Kosrae",
  },
  {
    code: "FM",
    name: "Micronesia, Federated States of",
    timezone: "Pacific/Pohnpei",
  },
  { code: "MD", name: "Moldova, Republic of", timezone: "Europe/Chisinau" },
  { code: "MC", name: "Monaco", timezone: "Europe/Monaco" },
  { code: "MN", name: "Mongolia", timezone: "Asia/Hovd" },
  { code: "MN", name: "Mongolia", timezone: "Asia/Ulaanbaatar" },
  { code: "ME", name: "Montenegro", timezone: "Europe/Podgorica" },
  { code: "MS", name: "Montserrat", timezone: "America/Montserrat" },
  { code: "MA", name: "Morocco", timezone: "Africa/Casablanca" },
  { code: "MA", name: "Morocco", timezone: "Africa/El_Aaiun" },
  { code: "MZ", name: "Mozambique", timezone: "Africa/Maputo" },
  { code: "MM", name: "Myanmar", timezone: "Asia/Yangon" },
  { code: "NA", name: "Namibia", timezone: "Africa/Windhoek" },
  { code: "NR", name: "Nauru", timezone: "Pacific/Nauru" },
  { code: "NP", name: "Nepal", timezone: "Asia/Kathmandu" },
  { code: "NL", name: "Netherlands", timezone: "Europe/Amsterdam" },
  { code: "NC", name: "New Caledonia", timezone: "Pacific/Noumea" },
  { code: "NZ", name: "New Zealand", timezone: "Pacific/Auckland" },
  { code: "NZ", name: "New Zealand", timezone: "Pacific/Chatham" },
  { code: "NI", name: "Nicaragua", timezone: "America/Managua" },
  { code: "NE", name: "Niger", timezone: "Africa/Niamey" },
  { code: "NG", name: "Nigeria", timezone: "Africa/Lagos" },
  { code: "NU", name: "Niue", timezone: "Pacific/Niue" },
  { code: "NF", name: "Norfolk Island", timezone: "Pacific/Norfolk" },
  { code: "MP", name: "Northern Mariana Islands", timezone: "Pacific/Saipan" },
  { code: "NO", name: "Norway", timezone: "Europe/Oslo" },
  { code: "OM", name: "Oman", timezone: "Asia/Muscat" },
  { code: "PK", name: "Pakistan", timezone: "Asia/Karachi" },
  { code: "PW", name: "Palau", timezone: "Pacific/Palau" },
  { code: "PS", name: "Palestine, State of", timezone: "Asia/Gaza" },
  { code: "PS", name: "Palestine, State of", timezone: "Asia/Hebron" },
  { code: "PA", name: "Panama", timezone: "America/Panama" },
  { code: "PG", name: "Papua New Guinea", timezone: "Pacific/Bougainville" },
  { code: "PG", name: "Papua New Guinea", timezone: "Pacific/Port_Moresby" },
  { code: "PY", name: "Paraguay", timezone: "America/Asuncion" },
  { code: "PE", name: "Peru", timezone: "America/Lima" },
  { code: "PH", name: "Philippines", timezone: "Asia/Manila" },
  { code: "PN", name: "Pitcairn", timezone: "Pacific/Pitcairn" },
  { code: "PL", name: "Poland", timezone: "Europe/Warsaw" },
  { code: "PT", name: "Portugal", timezone: "Atlantic/Azores" },
  { code: "PT", name: "Portugal", timezone: "Atlantic/Madeira" },
  { code: "PT", name: "Portugal", timezone: "Europe/Lisbon" },
  { code: "PR", name: "Puerto Rico", timezone: "America/Puerto_Rico" },
  { code: "QA", name: "Qatar", timezone: "Asia/Qatar" },
  { code: "RO", name: "Romania", timezone: "Europe/Bucharest" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Anadyr" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Barnaul" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Chita" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Irkutsk" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Kamchatka" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Khandyga" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Krasnoyarsk" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Magadan" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Novokuznetsk" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Novosibirsk" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Omsk" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Sakhalin" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Srednekolymsk" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Tomsk" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Ust-Nera" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Vladivostok" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Yakutsk" },
  { code: "RU", name: "Russian Federation", timezone: "Asia/Yekaterinburg" },
  { code: "RU", name: "Russian Federation", timezone: "Europe/Astrakhan" },
  { code: "RU", name: "Russian Federation", timezone: "Europe/Kaliningrad" },
  { code: "RU", name: "Russian Federation", timezone: "Europe/Kirov" },
  { code: "RU", name: "Russian Federation", timezone: "Europe/Moscow" },
  { code: "RU", name: "Russian Federation", timezone: "Europe/Samara" },
  { code: "RU", name: "Russian Federation", timezone: "Europe/Saratov" },
  { code: "RU", name: "Russian Federation", timezone: "Europe/Ulyanovsk" },
  { code: "RU", name: "Russian Federation", timezone: "Europe/Volgograd" },
  { code: "RW", name: "Rwanda", timezone: "Africa/Kigali" },
  { code: "RE", name: "Réunion", timezone: "Indian/Reunion" },
  { code: "BL", name: "Saint Barthélemy", timezone: "America/St_Barthelemy" },
  {
    code: "SH",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    timezone: "Atlantic/St_Helena",
  },
  { code: "KN", name: "Saint Kitts and Nevis", timezone: "America/St_Kitts" },
  { code: "LC", name: "Saint Lucia", timezone: "America/St_Lucia" },
  {
    code: "MF",
    name: "Saint Martin (French part)",
    timezone: "America/Marigot",
  },
  {
    code: "PM",
    name: "Saint Pierre and Miquelon",
    timezone: "America/Miquelon",
  },
  {
    code: "VC",
    name: "Saint Vincent and the Grenadines",
    timezone: "America/St_Vincent",
  },
  { code: "WS", name: "Samoa", timezone: "Pacific/Apia" },
  { code: "SM", name: "San Marino", timezone: "Europe/San_Marino" },
  { code: "ST", name: "Sao Tome and Principe", timezone: "Africa/Sao_Tome" },
  { code: "SA", name: "Saudi Arabia", timezone: "Asia/Riyadh" },
  { code: "SN", name: "Senegal", timezone: "Africa/Dakar" },
  { code: "RS", name: "Serbia", timezone: "Europe/Belgrade" },
  { code: "SC", name: "Seychelles", timezone: "Indian/Mahe" },
  { code: "SL", name: "Sierra Leone", timezone: "Africa/Freetown" },
  { code: "SG", name: "Singapore", timezone: "Asia/Singapore" },
  {
    code: "SX",
    name: "Sint Maarten (Dutch part)",
    timezone: "America/Lower_Princes",
  },
  { code: "SK", name: "Slovakia", timezone: "Europe/Bratislava" },
  { code: "SI", name: "Slovenia", timezone: "Europe/Ljubljana" },
  { code: "SB", name: "Solomon Islands", timezone: "Pacific/Guadalcanal" },
  { code: "SO", name: "Somalia", timezone: "Africa/Mogadishu" },
  { code: "ZA", name: "South Africa", timezone: "Africa/Johannesburg" },
  {
    code: "GS",
    name: "South Georgia and the South Sandwich Islands",
    timezone: "Atlantic/South_Georgia",
  },
  { code: "SS", name: "South Sudan", timezone: "Africa/Juba" },
  { code: "ES", name: "Spain", timezone: "Africa/Ceuta" },
  { code: "ES", name: "Spain", timezone: "Atlantic/Canary" },
  { code: "ES", name: "Spain", timezone: "Europe/Madrid" },
  { code: "LK", name: "Sri Lanka", timezone: "Asia/Colombo" },
  { code: "SD", name: "Sudan", timezone: "Africa/Khartoum" },
  { code: "SR", name: "Suriname", timezone: "America/Paramaribo" },
  {
    code: "SJ",
    name: "Svalbard and Jan Mayen",
    timezone: "Arctic/Longyearbyen",
  },
  { code: "SZ", name: "Swaziland", timezone: "Africa/Mbabane" },
  { code: "SE", name: "Sweden", timezone: "Europe/Stockholm" },
  { code: "CH", name: "Switzerland", timezone: "Europe/Zurich" },
  { code: "SY", name: "Syrian Arab Republic", timezone: "Asia/Damascus" },
  { code: "TW", name: "Taiwan, Province of China", timezone: "Asia/Taipei" },
  { code: "TJ", name: "Tajikistan", timezone: "Asia/Dushanbe" },
  {
    code: "TZ",
    name: "Tanzania, United Republic of",
    timezone: "Africa/Dar_es_Salaam",
  },
  { code: "TH", name: "Thailand", timezone: "Asia/Bangkok" },
  { code: "TL", name: "Timor-Leste", timezone: "Asia/Dili" },
  { code: "TG", name: "Togo", timezone: "Africa/Lome" },
  { code: "TK", name: "Tokelau", timezone: "Pacific/Fakaofo" },
  { code: "TO", name: "Tonga", timezone: "Pacific/Tongatapu" },
  {
    code: "TT",
    name: "Trinidad and Tobago",
    timezone: "America/Port_of_Spain",
  },
  { code: "TN", name: "Tunisia", timezone: "Africa/Tunis" },
  { code: "TR", name: "Turkey", timezone: "Europe/Istanbul" },
  { code: "TM", name: "Turkmenistan", timezone: "Asia/Ashgabat" },
  {
    code: "TC",
    name: "Turks and Caicos Islands",
    timezone: "America/Grand_Turk",
  },
  { code: "TV", name: "Tuvalu", timezone: "Pacific/Funafuti" },
  { code: "UG", name: "Uganda", timezone: "Africa/Kampala" },
  { code: "UA", name: "Ukraine", timezone: "Europe/Kyiv" },
  { code: "UA", name: "Ukraine", timezone: "Europe/Simferopol" },
  { code: "AE", name: "United Arab Emirates", timezone: "Asia/Dubai" },
  { code: "GB", name: "United Kingdom", timezone: "Europe/London" },
  { code: "US", name: "United States", timezone: "America/Adak" },
  { code: "US", name: "United States", timezone: "America/Anchorage" },
  { code: "US", name: "United States", timezone: "America/Boise" },
  { code: "US", name: "United States", timezone: "America/Chicago" },
  { code: "US", name: "United States", timezone: "America/Denver" },
  { code: "US", name: "United States", timezone: "America/Detroit" },
  {
    code: "US",
    name: "United States",
    timezone: "America/Indiana/Indianapolis",
  },
  { code: "US", name: "United States", timezone: "America/Indiana/Knox" },
  { code: "US", name: "United States", timezone: "America/Indiana/Marengo" },
  { code: "US", name: "United States", timezone: "America/Indiana/Petersburg" },
  { code: "US", name: "United States", timezone: "America/Indiana/Tell_City" },
  { code: "US", name: "United States", timezone: "America/Indiana/Vevay" },
  { code: "US", name: "United States", timezone: "America/Indiana/Vincennes" },
  { code: "US", name: "United States", timezone: "America/Indiana/Winamac" },
  { code: "US", name: "United States", timezone: "America/Juneau" },
  {
    code: "US",
    name: "United States",
    timezone: "America/Kentucky/Louisville",
  },
  {
    code: "US",
    name: "United States",
    timezone: "America/Kentucky/Monticello",
  },
  { code: "US", name: "United States", timezone: "America/Los_Angeles" },
  { code: "US", name: "United States", timezone: "America/Menominee" },
  { code: "US", name: "United States", timezone: "America/Metlakatla" },
  { code: "US", name: "United States", timezone: "America/New_York" },
  { code: "US", name: "United States", timezone: "America/Nome" },
  {
    code: "US",
    name: "United States",
    timezone: "America/North_Dakota/Beulah",
  },
  {
    code: "US",
    name: "United States",
    timezone: "America/North_Dakota/Center",
  },
  {
    code: "US",
    name: "United States",
    timezone: "America/North_Dakota/New_Salem",
  },
  { code: "US", name: "United States", timezone: "America/Phoenix" },
  { code: "US", name: "United States", timezone: "America/Sitka" },
  { code: "US", name: "United States", timezone: "America/Yakutat" },
  { code: "US", name: "United States", timezone: "Pacific/Honolulu" },
  {
    code: "UM",
    name: "United States Minor Outlying Islands",
    timezone: "Pacific/Midway",
  },
  {
    code: "UM",
    name: "United States Minor Outlying Islands",
    timezone: "Pacific/Wake",
  },
  { code: "UY", name: "Uruguay", timezone: "America/Montevideo" },
  { code: "UZ", name: "Uzbekistan", timezone: "Asia/Samarkand" },
  { code: "UZ", name: "Uzbekistan", timezone: "Asia/Tashkent" },
  { code: "VU", name: "Vanuatu", timezone: "Pacific/Efate" },
  {
    code: "VE",
    name: "Venezuela, Bolivarian Republic of",
    timezone: "America/Caracas",
  },
  { code: "VN", name: "Viet Nam", timezone: "Asia/Ho_Chi_Minh" },
  { code: "VG", name: "Virgin Islands, British", timezone: "America/Tortola" },
  { code: "VI", name: "Virgin Islands, U.S.", timezone: "America/St_Thomas" },
  { code: "WF", name: "Wallis and Futuna", timezone: "Pacific/Wallis" },
  { code: "YE", name: "Yemen", timezone: "Asia/Aden" },
  { code: "ZM", name: "Zambia", timezone: "Africa/Lusaka" },
  { code: "ZW", name: "Zimbabwe", timezone: "Africa/Harare" },
  { code: "AX", name: "Åland Islands", timezone: "Europe/Mariehamn" },
];

export function getCodeFromTimezone(timezone) {
  if (!timezone || String(timezone).trim().length === 0) return null;
  const finalTimezone = String(timezone).toLowerCase();
  for (let i = 0; i < map.length; i++) {
    const lZone = String(map[i].timezone).toLowerCase();
    if (finalTimezone === lZone) {
      return map[i].code;
    }
  }
  return null;
}

export function getCountryCode() {
  const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
  if (!timezone) return null;
  return getCodeFromTimezone(timezone);
}
