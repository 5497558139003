import React from "react";
import WarningLayout from "../Components/WarningLayout";
import PhoneNumber from "../Components/PhoneNumber";
import InformationTable from "../Components/InformationTable";

export default function MainPage() {
  return (
    <div>
      <PhoneNumber />
      <WarningLayout
        title={"Cras elit libero"}
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam convallis tempus diam, ut commodo dui aliquet at. Cras elit libero, pretium non pretium sit amet, lacinia vel est. Nullam gravida sodales massa. Sed blandit lacus et sollicitudin sagittis. Nullam at erat ut odio sollicitudin tristique. Aliquam blandit imperdiet consequat."
      />
      <InformationTable />
      <WarningLayout
        title={"Cras elit libero"}
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam convallis tempus diam, ut commodo dui aliquet at. Cras elit libero, pretium non pretium sit amet, lacinia vel est. Nullam gravida sodales massa. Sed blandit lacus et sollicitudin sagittis. Nullam at erat ut odio sollicitudin tristique. Aliquam blandit imperdiet consequat."
      />
    </div>
  );
}
