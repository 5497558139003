import { Box, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  appGrey,
  appRed,
  boxPadding,
  textBlue,
  textColor,
} from "../Styles/styles";
import { Motion } from "./Motion";
import Table from "./Table";

export default function InformationTable({}) {
  const [hidden, setHidden] = useState(false);

  if (hidden) return <></>;

  return (
    <Container maxWidth="lg" sx={{ pb: "24px" }}>
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ...boxPadding,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Motion>
              <Box
                sx={{
                  height: "25px",
                  maxHeight: "25px",
                }}
              >
                <InfoRoundedIcon sx={{ fill: textBlue }} />
              </Box>
            </Motion>
            <Typography sx={{ ml: "8px", fontWeight: "bold" }}>
              {"Avaiable information"}
            </Typography>
            <Motion>
              <Box
                sx={{
                  ml: "auto",
                  cursor: "pointer",
                  p: "3px",
                  height: "24px",
                  maxHeight: "24px",
                }}
                onClick={() => {
                  setHidden(true);
                }}
              >
                <CloseRoundedIcon sx={{ fill: textColor }} />
              </Box>
            </Motion>
          </Box>
          <Table />
        </Box>
      </Box>
    </Container>
  );
}
