import React from "react";
import PhoneIphoneRoundedIcon from "@mui/icons-material/PhoneIphoneRounded";
import { Box, Container, Typography } from "@mui/material";
import { Motion } from "./Motion";
import { boxPadding, textBlue } from "../Styles/styles";

export default function PhoneNumber() {
  return (
    <Container maxWidth="lg" sx={{ pb: "24px" }}>
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ...boxPadding,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              alignContent: "center",
              textAlign: "left",
            }}
          >
            <Motion>
              <Box
                sx={{
                  width: "16px",
                  height: "18px",
                  maxHeight: "18px",
                }}
              >
                <PhoneIphoneRoundedIcon
                  sx={{ fill: textBlue, width: "16px", height: "16px" }}
                />
              </Box>
            </Motion>
            <Typography sx={{ ml: "8px", fontWeight: "bold", color: textBlue }}>
              {"Phone number to check"}
            </Typography>
            <Motion>
              <Box
                sx={{
                  ml: "auto",
                  p: "3px",
                  height: "24px",
                  maxHeight: "24px",
                }}
              ></Box>
            </Motion>
          </Box>
          <Typography sx={{ fontWeight: "bold", fontSize: "32px" }}>
            {"123453"}
          </Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            {"Other formats:"}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
