import logo from "./logo.svg";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";
import coreThemeFile from "./Resources/Styles/styles";
import MainContainer from "./Resources/Pages/MainContainer";
import ScrollUpComp from "./Resources/Components/ScrollUpComp";
import { Route, Routes } from "react-router-dom";

export const theme = createTheme(coreThemeFile());

function App() {
  console.log(navigator.language, navigator.userLanguage);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <ScrollUpComp />
        <Routes>
          <Route path="*" element={<MainContainer />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
