import parsePhoneNumberFromString, {
  parsePhoneNumber,
} from "libphonenumber-js/max";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Navbar from "../Components/Navbar/Navbar";
import { useCountryCode } from "../Countries/useCountryCode";
import { appBgColor, navPalette } from "../Styles/styles";
import MainPage from "./MainPage";
import { Box } from "@mui/material";
import Footer from "../Components/Footer";

export default function MainContainer({}) {
  const { number } = useParams();
  const location = useLocation();
  const [colors, setColors] = useState(null);
  const { bg, txt } = colors || {};
  const countryCode = useCountryCode();

  useEffect(() => {
    let locCol =
      colors || navPalette[Math.floor(Math.random() * navPalette.length)];
    let { bg: bgLoc, txt: txtLoc } = locCol;
    while (bgLoc === bg && txtLoc === txt) {
      locCol = navPalette[Math.floor(Math.random() * navPalette.length)];
      bgLoc = locCol.bg;
      txtLoc = locCol.txt;
    }
    setColors(locCol);
  }, [location]);

  useEffect(() => {
    // parsePhoneNumberFromString("739 880 528");
    const phoneNumber = parsePhoneNumberFromString("+420739880528"); //parsePhoneNumber(" 8 (800) 555-35-35 ",'RU');
    console.log(phoneNumber);
  }, []);

  return (
    <>
      <div style={{ backgroundColor: appBgColor, minHeight: "100vh" }}>
        <Navbar {...colors} country={countryCode} />
        <Box sx={{ pt: "80px" }}>
          <MainPage />
        </Box>
      </div>
      <Footer {...colors} country={countryCode} />
    </>
  );
}
