import {
  Autocomplete,
  Box,
  Container,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Books from "./Books";
import { textColor } from "../../Styles/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

function Logo({ color = textColor }) {
  return (
    <Box
      component="a"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        textDecoration: "none",
      }}
      href="https://metanery.com/"
    >
      <Books fill={color} style={{ width: "24px", height: "24px" }} />

      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: "900",
          mx: "7px",
          color,
          textDecoration: "none",
        }}
      >
        CALLNUMBERS
      </Typography>
    </Box>
  );
}

function SearchBar({ color = "#000000", removeOutline = false, country }) {
  const [inputValue, setInputValue] = useState("");

  return (
    <>
      <Box
        sx={{
          ml: "auto",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <PhoneInput
          country={String(country).toLowerCase()}
          value={inputValue}
          onChange={setInputValue}
          placeholder="Search number"
        />
      </Box>
    </>
  );
}

export default function Navbar({ threshold = 0, ...props }) {
  const { word } = useParams();
  const { bg, txt, removeOutline, country } = props;

  const navigation = [
    {
      name: "Link1",
      link: `https://metanery.com/`,
    },
    {
      name: "Link2",
      link: `https://metanery.com/`,
    },
  ];

  const elevated = useScrollTrigger({
    disableHysteresis: true,
    threshold,
    target: window ? window : undefined,
  });

  return (
    <header>
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          top: 0,
          bgcolor: bg,
          boxShadow: elevated ? "0px 1px 5px 0px rgba(0,0,0,0.05)" : "none",
          zIndex: 1000,
        }}
      >
        <Container maxWidth="lg" sx={{ height: "50px", width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Logo color={txt} />
            <Box
              sx={{ display: { xs: "none", sm: "flex" }, flexDirection: "row" }}
            >
              {navigation.map((nav, i) => (
                <Typography
                  component={"a"}
                  key={"NavText" + i}
                  sx={{
                    fontSize: "14px",
                    ml: "14px",
                    color: txt,
                    textDecoration: "none",
                  }}
                  href={nav.link}
                >
                  {nav.name}
                </Typography>
              ))}
            </Box>
            <SearchBar
              color={txt}
              removeOutline={removeOutline}
              country={country}
            />
          </Box>
        </Container>
      </Box>
    </header>
  );
}
