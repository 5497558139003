import {
  Autocomplete,
  Box,
  Container,
  Typography,
  useMediaQuery,
  useScrollTrigger,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { useNavigate } from "react-router-dom";
import Books from "./Navbar/Books";
import CookieIcon from "@mui/icons-material/Cookie";
import { theme } from "../../App";
import CookieBar from "./CookieBar";
import { textColor } from "../Styles/styles";
import { Motion } from "./Motion";
import PhoneInput from "react-phone-input-2";

function Logo({ color = textColor }) {
  return (
    <Box
      component="a"
      sx={{
        display: "flex",
        flexDirection: "row",

        alignItems: "center",
        alignContent: "center",
        textDecoration: "none",
      }}
      href="https://metanery.com/"
    >
      <Books fill={color} style={{ width: "24px", height: "24px" }} />

      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: "900",
          mx: "7px",
          color,
          textDecoration: "none",
        }}
      >
        CALLNUMBERS
      </Typography>
    </Box>
  );
}

function SearchBar({ color = "#000000", removeOutline = false, country }) {
  const [inputValue, setInputValue] = useState("");

  return (
    <>
      <Box
        sx={{
          ml: "auto",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <PhoneInput
          country={String(country).toLowerCase()}
          value={inputValue}
          onChange={setInputValue}
          placeholder="Search number"
        />
      </Box>
    </>
  );
}

export default function Footer({ threshold = 0, ...props }) {
  const [cookieOpen, setCookieOpen] = useState(false);
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const { bg, txt, removeOutline, country } = props;

  const elevated = useScrollTrigger({
    disableHysteresis: true,
    threshold,
    target: window ? window : undefined,
  });

  return (
    <footer>
      <CookieBar open={cookieOpen} setOpen={setCookieOpen} {...props} />
      <Box
        sx={{
          width: "100%",
          // position: "absolute",
          //bottom: 0,
          bgcolor: bg,
          boxShadow: elevated ? "0px -1px 5px 0px rgba(0,0,0,0.05)" : "none",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            height: "160px",
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            alignContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              py: "32px",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              alignContent: "flex-start",
            }}
          >
            <Logo color={txt} />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                ml: "auto",
                alignItems: "flex-end",
                alignContent: "flex-end",
                height: "100%",
              }}
            >
              <SearchBar
                color={txt}
                removeOutline={removeOutline}
                country={country}
              />
              <Motion>
                <Box
                  sx={{
                    mt: "auto",
                    width: { xs: "124px", sm: "154px" },
                    height: "31px",
                    bgcolor: txt,
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    cursor: "pointer",
                    opacity: 1,
                  }}
                  onClick={() => {
                    setCookieOpen(true);
                  }}
                >
                  <CookieIcon
                    sx={{
                      fill: bg,
                      mr: "auto",
                      width: "30px",
                      height: "30px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: bg,
                      fontWeight: "bold",
                      pr: { xs: "30px", sm: "22px" },
                      fontSize: "14px",
                      pt: "2px",
                    }}
                  >
                    {xs ? "Cookies" : "Cookies Policy"}
                  </Typography>
                </Box>
              </Motion>
            </Box>
          </Box>
        </Container>
      </Box>
    </footer>
  );
}
