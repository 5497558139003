import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Motion } from "./Motion";
import { setConsent } from "firebase/analytics";
import CookieIcon from "@mui/icons-material/Cookie";
import { theme } from "../../App";

const cookies = "cookies_accepted";

const pcFirst =
  "We're glad to have you with us. We use cookies to improve the functionality of our website and to provide relevant content and services.";
const pcTablet = `If you agree to our use of cookies, please click the "Accept All" button. For more information on how we process personal data and use cookies, please read our`;
const mobileText = `If you agree to our use of cookies, click the "Accept All" button.`;

// Full default
export const fullConsent = {
  ad_storage: "granted",
  ad_user_data: "granted",
  ad_personalization: "granted",
  analytics_storage: "granted",
  functionality_storage: "granted",
  personalization_storage: "granted",
  security_storage: "granted",
};

// Consent Required Always
const partialConsent = {
  ad_storage: "denied",
  ad_user_data: "denied",
  ad_personalization: "denied",
  analytics_storage: "denied",
  functionality_storage: "granted",
  personalization_storage: "granted",
  security_storage: "granted",
};

function acceptCookies(all = false) {
  if (!localStorage) return;
  localStorage.setItem(cookies, all ? "all" : "true");
}

export function areCookiesAccepted() {
  const cookies = "cookies_accepted";
  if (!localStorage) return true;
  return Boolean(localStorage?.getItem(cookies) === "all");
}

export default function CookieBar({ open, setOpen, bg, txt }) {
  const [shown, setShown] = useState(
    open !== undefined ? open : !areCookiesAccepted()
  );
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));
  const xss = useMediaQuery(theme.breakpoints.down("440"));

  useEffect(() => {
    if (open !== undefined && shown != open) {
      setShown(open);
    }
  }, [open]);

  const allEndText = (
    <>
      {" "}
      <a
        href="https://metanery.com/cookies"
        target="_blank"
        rel="noreferrer"
        style={{ whiteSpace: "nowrap", color: txt }}
      >
        Cookies policy
      </a>
    </>
  );

  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: shown ? "220px" : "0px",
        position: "fixed",
        bottom: 0,
        zIndex: 1000,
        //boxShadow: "0px -1px 5px 0px rgba(115,117,122,0.75)",
        transition: "max-height .4s ease-in-out",
        textAlign: "left",
      }}
    >
      <Box
        sx={{
          height: "auto",
          minHeight: "120px",
          bgcolor: bg,
          opacity: 0.96,
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {!xss && (
          <CookieIcon
            sx={{
              fill: txt,
              width: "42px",
              height: "42px",
              m: "20px",
              mt: "24px",
            }}
          />
        )}
        <Typography sx={{ color: txt, m: "20px", ml: xss ? "20px" : "0px" }}>
          {!(mobile || tablet) ? <>{pcFirst} </> : ""}
          {mobile ? mobileText : pcTablet}
          {allEndText}
        </Typography>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            ml: "auto",
            mr: "20px",
            my: "20px",
          }}
        >
          <Motion>
            <Box
              sx={{
                minWidth: "120px",
                height: "34px",
                bgcolor: txt,
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                cursor: "pointer",
                opacity: 1,
              }}
              onClick={() => {
                setConsent(fullConsent);
                acceptCookies(true);
                if (setOpen) setOpen(false);
                setShown(false);
              }}
            >
              <Typography sx={{ color: bg, fontWeight: "bold" }}>
                Accept All
              </Typography>
            </Box>
          </Motion>
          <Typography
            sx={{
              color: txt,
              fontWeight: "bold",
              mt: "7px",
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => {
              setConsent(partialConsent);
              acceptCookies();
              if (setOpen) setOpen(false);
              setShown(false);
            }}
          >
            Only necessary
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
